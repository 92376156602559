<script lang="ts" setup generic="T extends { id: number | string }">
import type { VNode } from 'vue';

defineProps<{
  sliderClass?: string;
  sliderSlideClass?: string;
  navigationClass?: string;
  actionLink?: string;
  list: T[];
  slidesPerView: 'auto' | { mobile?: number; tablet: number; desktop: number };
  mobileGrid?: boolean;
  withPaddingTop?: boolean;
}>();
const slots = defineSlots<{
  title?: () => VNode[];
  subtitle?: () => VNode[];
  header?: () => VNode[];
  slide: (props: { item: T }) => VNode[];
}>();

const { t } = useI18n();
</script>

<template>
  <section
    class="baseSliderSection"
    :class="{
      baseSliderSection_mobileGrid: mobileGrid,
      baseSliderSection_paddingTop: withPaddingTop,
    }"
  >
    <div class="baseSliderSection__header">
      <h2 v-if="slots.title" class="baseSliderSection__title">
        <slot name="title" />

        <span class="baseSliderSection__title_secondary">
          <slot name="subtitle" />
        </span>
      </h2>
      <div v-else-if="slots.header" class="baseSliderSection__headerContent">
        <slot name="header" />
      </div>
    </div>

    <BaseControlledSlider
      :space-between="{ mobile: 12, desktop: 18 }"
      :slides-per-view="slidesPerView"
      :slider-class="`${sliderClass} baseSliderSection__slider`.trim()"
      :slider-slide-class="sliderSlideClass"
      :navigation-class="navigationClass"
      :list="list"
      :mobile-disabled="mobileGrid"
    >
      <template #slide="{ item }">
        <slot name="slide" :item="item as T" />
      </template>
    </BaseControlledSlider>

    <NuxtLink
      v-if="actionLink"
      :to="actionLink"
      class="baseSliderSection__link"
    >
      <BaseButton class="baseSliderSection__more" outlined dark>
        {{ t('show') }}
      </BaseButton>
    </NuxtLink>
  </section>
</template>

<i18n>
ru:
  show: Смотреть все

en:
  show: Show all
</i18n>

<style lang="scss">
.baseSliderSection {
  $self: &;

  --slider-padding-top: 26px;

  position: relative;
  padding: 20px 0;

  @include mq('sm') {
    &_mobileGrid &__slider {
      .swiper-wrapper {
        transform: initial !important;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 24px;
      }

      .swiper-slide {
        width: auto !important;
        margin-right: 0 !important;

        &:nth-child(4) ~ * {
          display: none;
        }
      }
    }
  }

  & &__slider {
    padding: 0 20px;

    @include mq('sm') {
      padding: 0 16px;
    }
  }

  &#{$self}_paddingTop &__slider {
    padding-top: var(--slider-padding-top);
    margin-top: calc(var(--slider-padding-top) * -1);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px 29px;

    @include mq('sm') {
      padding: 0 16px 25px;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin: 0;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__headerContent {
    display: flex;
    gap: 34px;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 20px;
    display: block;

    @include mq('sm') {
      position: initial;
      margin-top: 28px;
      width: 100%;
      padding: 0 16px;
    }

    &:empty {
      display: none;
    }
  }

  &__more {
    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
