<script lang="ts" setup generic="T extends { id: number | string }">
import { Controller } from 'swiper/modules';
import type { Swiper as SwiperClass, SwiperOptions } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { VNode } from 'vue';

const props = defineProps<{
  sliderClass?: string;
  sliderSlideClass?: string;
  navigationClass?: string;
  list: T[];
  slidesPerView: 'auto' | { mobile?: number; tablet: number; desktop: number };
  spaceBetween: { mobile: number; desktop: number };
  mobileDisabled?: boolean;
}>();
defineSlots<{
  slide: (props: { item: T }) => VNode[];
}>();

const { t } = useI18n();

const controlledSwiper = ref<SwiperClass>();
const isStart = ref(true);
const isEnd = ref(true);

const breakpoints = computed<SwiperOptions['breakpoints']>(() => ({
  0: props.mobileDisabled
    ? { enabled: false }
    : {
        spaceBetween: props.spaceBetween.mobile,
        slidesPerView:
          props.slidesPerView === 'auto'
            ? props.slidesPerView
            : props.slidesPerView.mobile,
      },
  768: {
    spaceBetween: props.spaceBetween.desktop,
    enabled: true,
    slidesPerView:
      props.slidesPerView === 'auto'
        ? props.slidesPerView
        : props.slidesPerView.tablet,
  },
  1240: {
    spaceBetween: props.spaceBetween.desktop,
    slidesPerView:
      props.slidesPerView === 'auto'
        ? props.slidesPerView
        : props.slidesPerView.desktop,
  },
}));
const key = computed(() => props.list.map((el) => el.id).join('_'));

const setControlledSwiper = (swiper: SwiperClass) => {
  controlledSwiper.value = swiper;
};

const updateNavigationVisible = (event: SwiperClass) => {
  isStart.value = event.isBeginning;
  isEnd.value = event.isEnd;
};

onBeforeUnmount(() => {
  controlledSwiper.value?.destroy();
});
</script>

<template>
  <div class="baseControlledSlider">
    <Transition name="baseControlledSlider" mode="out-in">
      <Swiper
        :key="key"
        :pagination="{ clickable: true }"
        :class="sliderClass"
        :modules="[Controller]"
        :controller="{ control: controlledSwiper }"
        @slide-change-transition-end="updateNavigationVisible"
        @init="updateNavigationVisible"
        @resize="updateNavigationVisible"
        @reach-beginning="updateNavigationVisible"
        @reach-end="updateNavigationVisible"
        @swiper="setControlledSwiper"
        :breakpoints="breakpoints"
      >
        <SwiperSlide
          v-for="item in list"
          :key="item.id"
          :class="sliderSlideClass"
        >
          <slot name="slide" :item="item" />
        </SwiperSlide>
      </Swiper>
    </Transition>

    <Transition name="fade">
      <BaseButton
        v-if="!isStart"
        @click="controlledSwiper?.slidePrev()"
        icon="caret"
        size="s"
        class="baseControlledSlider__button baseControlledSlider__button_prev"
        :class="navigationClass"
        :aria-label="t('prev')"
      />
    </Transition>
    <Transition name="fade">
      <BaseButton
        v-if="!isEnd"
        @click="controlledSwiper?.slideNext()"
        icon="caret"
        size="s"
        class="baseControlledSlider__button baseControlledSlider__button_next"
        :class="navigationClass"
        :aria-label="t('next')"
      />
    </Transition>
  </div>
</template>

<i18n>
ru:
  prev: Предыдущий слайд
  next: Следующий слайд

en:
  prev: Previous slide
  next: Next slide
</i18n>

<style lang="scss">
.baseControlledSlider {
  --navigation-delta: calc(var(--slider-padding-top, 0px));

  position: relative;

  & &__button {
    position: absolute;

    @include mq('sm') {
      display: none;
    }
  }

  &__button {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    &_prev {
      left: 29px;

      svg {
        transform: rotate(180deg);
      }
    }

    &_next {
      right: 29px;
    }
  }

  &-enter-active,
  &-leave-active {
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>
